import { useEffect, useState, useRef } from 'react'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//asset
import DiscoveryNoDataLight from '../../../assets/json/discovery-nodata-white.json'
import DiscoveryNoData from '../../../assets/json/discovery-no-data.json'

//lib
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import Lottie from 'lottie-react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-responsive-masonry'
import { Icon } from '@iconify/react'
import { motion } from 'framer-motion'

//component
import VideoCard from './video.card'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getVideos } from '../../../redux/reducer/discoverReducer'
import { setData, setHasMore, setInitialLoading } from '../../../redux/reducer/discoverReducer'

export default function VideoList({ search }) {
  const { width } = useWindowDimensions()
  const dispatch = useDispatch()
  const [start, setStart] = useState(0)
  const [more, setMore] = useState(true)
  const [loader, setLoader] = useState(false)
  const [screenWidth, setScreemwidth] = useState(0)
  const { videoList, data, hasMore, inIntialLoading, loading, length } = useSelector(
    (state) => state.discover,
  )
  const { isDarkMode } = useSelector((state) => state.home)
  const animationStyle = {
    width: width >= 991 ? '20%' : '60%',
  }

  const getMoreData = () => {
    setLoader(true)
    dispatch(
      getVideos({
        search: search,
        length: length,
        start: start + 8,
      }),
    )
      .unwrap()
      .then((res) => {
        setLoader(false)
        setStart(start + 8)
        let newData = []
        for (var key in res.data.aaData) {
          newData.push({
            ...res.data.aaData[key],
          })
        }
        if (search === '' && data.length + newData.length >= res.data.iTotalRecords) {
          dispatch(setHasMore(false))
        } else if (search !== '' && data.length + newData.length >= res.data.iTotalDisplayRecords) {
          dispatch(setHasMore(false))
        }
        dispatch(setData([...data, ...newData]))
      })
      .catch((ex) => {})
  }

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  useEffect(() => {
    setScreemwidth(width)
  }, [width])

  return (
    <MDBContainer className="discover-page-container" style={{ maxWidth: '1140px' }}>
      <MDBRow className="p-0 m-0">
        <label className="_label --discover-title">Discover Supercar Spotlight 🏁</label>
      </MDBRow>
      <MDBRow className="p-0 m-0">
        <label className="_label --discover-text">See it. Hear it. Know it. Make your move.</label>
      </MDBRow>
      {data.length > 0 ? (
        <InfiniteScroll
          style={{ overflow: 'unset' }}
          dataLength={data.length}
          hasMore={hasMore}
          next={() => {
            if (screenWidth < 991) {
              getMoreData()
            }
          }}
          loader={
            width < 991 &&
            data.length > 0 && (
              <h4 className="text-center mt-5" style={{ color: '#D1d5db' }}>
                Loading...
              </h4>
            )
          }>
          <MDBRow>
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              style={{ padding: width > 991 ? '' : 0 }}>
              <Masonry columnsCount={width > 1200 ? 4 : width > 991 ? 3 : 2} gutter="0px">
                {data.length > 0 &&
                  data.map((item, index) => {
                    return <VideoCard key={index} item={item} index={index} />
                  })}
              </Masonry>
            </motion.div>
          </MDBRow>
        </InfiniteScroll>
      ) : (
        <MDBRow
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            marginTop: '6em',
            marginLeft: '0',
            marginRight: '0',
            color: '#D1D5DB',
            fontFamily: 'cabin-regular',
          }}>
          <label
            style={{
              fontFamily: 'inter-regular',
              fontSize: '22px',
              fontWeight: '600',
              color: '#33363b',
              fontStyle: 'italic',
              textTransform: 'capitalize',
            }}>
            No data available
          </label>
          {isDarkMode ? (
            <Lottie animationData={DiscoveryNoData} style={animationStyle} />
          ) : (
            <Lottie animationData={DiscoveryNoDataLight} style={animationStyle} />
          )}
        </MDBRow>
      )}
      <div style={{ textAlign: 'center' }} className="btn-div">
        {width > 991 && data.length < videoList?.data?.iTotalRecords && (
          <button className="_btn" onClick={() => getMoreData()} type="button" disabled={loader}>
            <span>
              VIEW MORE {loader && <Icon icon="line-md:loading-twotone-loop" height={'1.2em'} />}
            </span>
          </button>
        )}
      </div>
      {/* <div class="elfsight-app-d7fa5558-1240-49f2-ba9e-4e2660aac12b"></div> */}
    </MDBContainer>
  )
}
