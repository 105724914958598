import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenVersionModal } from '../../redux/reducer/homeReducer'
import {
  MDBRow,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit' // Added MDBModalDialog, MDBModalContent, and MDBModalBody
import { UnderMaintenance, UpdateVersion } from '../../assets/images'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const VersionUpdatePage = () => {
  const dispatch = useDispatch()
  const { openVersionModal } = useSelector((state) => state.home)

  return (
    <MDBModal
      show={openVersionModal === 'version' || openVersionModal === 'maintenance'}
      tabIndex="-1"
      staticBackdrop>
      <MDBModalDialog centered size="lg" className="update-modal">
        <MDBModalContent>
          <MDBModalBody>
            <div
              className="vh"
              style={{
                padding: '2rem',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center',
                // height: '100%',
                minHeight: '100px',
                // backgroundColor: '#f7f7f7',
                // borderRadius: '.5rem',
                // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                // zIndex: 9999,
              }}>
              <div className="update-wrap">
                <div>
                  <div className="update-frame">
                    <LazyLoadImage
                      src={openVersionModal === 'version' ? UpdateVersion : UnderMaintenance}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </div>
                </div>
                <h1>
                  {openVersionModal === 'version' ? 'Version Outdated' : `We're Under Maintenance`}
                </h1>

                <p className="ps-3 pe-3">
                  {openVersionModal === 'version'
                    ? 'Your current version is outdated. Please click the button below to refresh and update your version.'
                    : 'Our system is currently undergoing maintenance to serve you better. Please check back soon. Thank you for your patience!'}
                </p>

                {openVersionModal === 'version' ? (
                  <MDBBtn
                    class="btn btn-primary btn-lg"
                    type="button"
                    onClick={() => {
                      if (openVersionModal === 'version') {
                        dispatch(setOpenVersionModal(null))
                        window.location.reload()
                      }
                    }}>
                    Update Now
                  </MDBBtn>
                ) : (
                  <div className="mb-3" />
                )}
              </div>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}

export default VersionUpdatePage
