import { useEffect, useState } from 'react'

//redux
import { getVideos } from '../../redux/reducer/discoverReducer'
import { useDispatch, useSelector } from 'react-redux'
import { setHasMore, setInitialLoading, setData } from '../../redux/reducer/discoverReducer'
//hook
import useWindowDimensions from '../../components/common/window-dimension'
import { Helmet } from 'react-helmet'

//lib
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import Lottie from 'lottie-react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { motion } from 'framer-motion'

//assets
import DiscoveryNoData from './../../assets/json/discovery-no-data.json'

//components
import SearchBar from '../../components/element/searchbar'
import VideoList from './component/video.list'
import VideoInfo from './component/video.info'

//helper
import LazyImage from '../../components/common/common.image'

export default function Discover() {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const { length, selectedVideo } = useSelector((state) => state.discover)
  const [search, setSearch] = useState('')
  const [animation, setAnimation] = useState(false)

  const animationStyle = {
    width: width >= 991 ? '20%' : '60%',
  }

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop || document.body.scrollTop
    if (scrolled > 300) {
      setAnimation(true)
    } else if (scrolled < 300) {
      setAnimation(false)
    }
  }

  document.addEventListener('scroll', toggleVisible)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(
      getVideos({
        search: search,
        length: length,
        start: 0,
      }),
    )
      .unwrap()
      .then((res) => {
        dispatch(setInitialLoading(false))
        let newData = []
        for (var key in res.data.aaData) {
          newData.push({
            ...res.data.aaData[key],
          })
        }
        dispatch(setData(newData))
        if (newData.length !== res.data.iTotalRecords) {
          dispatch(setHasMore(true))
        }
      })
      .catch((ex) => {})
  }, [search])

  return (
    <MDBContainer
      className="common-main-container"
      breakpoint="none"
      // style={{ background: '#14161E' }}
    >
      <Helmet>
        <title>Welcome to Dconcept</title>
        <meta name="Dconcept" content="Welcome to Dconcept" />
      </Helmet>
      {selectedVideo?.description !== undefined && <VideoInfo setSearch={setSearch} />}
      <MDBContainer
        style={{
          paddingTop: width >= 991 ? '10em' : '0rem',
          paddingLeft: '0em',
          paddingRight: '0em',
        }}>
        <SearchBar className={'--stock'} setValue={setSearch} value={search} />
        <VideoList search={search} />
        {animation && (
          <div
            onClick={() => {
              window.scrollTo(0, 0)
            }}
            id="car-scroll-top"
            className="car-scroll-top"
            style={{
              position: 'fixed',
              bottom: '2.5em',
              right: '2.5em',
              zIndex: 999,
            }}>
            <div className="car-container" initial="hidden" animate="show">
              <LazyImage
                width={width >= 991 ? 'auto' : 50}
                src={require('../../assets/images/icon/car.webp')}
                alt="d-concept"
              />
              <LazyImage
                width={width >= 991 ? 'auto' : 50}
                className="car-focus"
                src={require('../../assets/images/icon/car-focus.webp')}
                alt="d-concept"
              />
            </div>
          </div>
        )}
      </MDBContainer>
      {/* <FooterOne /> */}
    </MDBContainer>
  )
}
