import { useEffect, useRef, useState } from 'react'
import VideoCard from '../../assets/videos/homepage/homeBanner.mp4'
//lib
import { MDBContainer } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Helmet } from 'react-helmet'

//component
import SearchBar from './component/search.bar'
import WhatWeDo from './component/what.we.do'
import WhatWeDoV2 from './component/what.we.do.v2'
import DirectImport from './component/direct.import'
import LastView from './component/last.view'
import ReadyStock from './component/ready.stock'
import ReadyStock2 from './component/ready.stock2'
import LastSearch from './component/last.search'
import HowItWork from './component/how.it.work'
import LastSeen from './component/car.branch'
import CustomerSay from './component/customer.say'
import CustomerSay2 from './component/customer.say2'
import Title from './component/home.title'
//modalbox
import SearchModalBox from './component/search.modalbox'

//assets
import { HomeBannerImage } from '../../assets/images'
import HomeBannerImageMobile from '../../assets/images/background/home-banner-mobile.webp'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { postLastView } from '../../redux/reducer/homeReducer'
import Login from '../Login'
import useWindowDimensions from '../../components/common/window-dimension'

export default function Home() {
  const dispatch = useDispatch()
  const { width, height } = useWindowDimensions()
  const { login_modal } = useSelector((state) => state.order)
  const [searchModalBox, setSearchModalBox] = useState(false)
  const selectedPosts = JSON.parse(localStorage.getItem('selectedPosts')) || []

  const storedBrand = JSON.parse(localStorage.getItem('selectedItems')) || []

  const componentARef = useRef(null)

  useEffect(() => {
    dispatch(
      postLastView({
        post_id: selectedPosts,
        brand_id: storedBrand,
      }),
    )
      .unwrap()
      .then((res) => {
        localStorage.removeItem('selectedPosts')
        localStorage.removeItem('selectedItems')
        localStorage.setItem('selectedPosts', JSON.stringify(res.available_last_view))
        localStorage.setItem('selectedItems', JSON.stringify(res.available_last_search))
      })
      .catch((ex) => {})
  }, [dispatch])

  return (
    <MDBContainer className="common-main-container --grey" breakpoint="none" ref={componentARef}>
      <Helmet>
        <title>Welcome to Dconcept</title>
        <meta name="Dconcept" content="Welcome to Dconcept" />
      </Helmet>
      <MDBContainer className="home-banner">
        {width >= 653 ? (
          <video
            autoPlay
            muted
            loop
            style={{
              height: width >= 653 && width < 992 ? height : height - 119,
              top: width >= 653 && width < 992 ? '-186px' : '',
            }}>
            <source src={VideoCard} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <LazyLoadImage
            src={width >= 991 ? HomeBannerImage : HomeBannerImageMobile}
            alt="home-background-img"
          />
        )}
      </MDBContainer>
      <div className="content-container home-page-container">
        <Title />
        {/* <SearchBar setShow={setSearchModalBox} /> */}
        {/* <WhatWeDoV2 /> */}
        <WhatWeDo />
        <DirectImport />
        <HowItWork />
        {/* <ReadyStock /> */}
        <ReadyStock2 />
        <div className="line-container --gold"></div>
        <LastView />
        {/* <LastSearch /> */}
        <LastSeen />
        {/* <CustomerSay/> */}
        <CustomerSay2 />
      </div>
      {searchModalBox && <SearchModalBox setShow={setSearchModalBox} show={searchModalBox} />}
      {login_modal && <Login show={login_modal} />}
    </MDBContainer>
  )
}
