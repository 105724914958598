import axios from 'axios'
import { toast } from 'react-toastify'
import { setOpenVersionModal } from '../redux/reducer/homeReducer'

function handleErrorResponse(error, dispatch) {
  const authError = error.response && error.response.status === 401
  const exptectedError = error.response && error.response.status >= 404
  const versionError = error.response && error.response.status === 410
  const maintenanceError = error.response && error.response.status === 503
  const unexpectedError = error.response.status === 500

  if (authError) {
    localStorage.clear()

    // return (window.location = "/login");
  }

  if (versionError) {
    // dispatch(setOpenVersionModal('version'))
    window.location = `?open=update_version`
  }

  if (maintenanceError) {
    // dispatch(setOpenVersionModal('maintenance'))
    window.location = `?open=under_maintenance`
  }

  if (unexpectedError) {
    // eslint-disable-next-line default-case
    // logger.log(error);
    toast.error('An unexpected error occurred @contact support', {
      theme: 'colored',
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
    })
  }
  //this.props.history.push("/error500");
  //window.location = "/error500";
  return Promise.reject(error)
}

function initializeInterceptors(dispatch) {
  axios.interceptors.response.use(null, (error) => handleErrorResponse(error, dispatch))
}

function setToken(token) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    Accept: `application/json`,
  }
  axios.defaults.headers.common['User-Version'] = process.env.REACT_APP_VERSION
}

const httpService = {
  get: axios.get,
  patch: axios.patch,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
  initializeInterceptors,
}

export default httpService
